.dashboard-account {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboardConatiner {
    font-family: "Poppins", sans-serif !important;
    font-size: 24px !important;
    font-weight: 700 !important;

    text-align: center;
    color: #FFC540 !important;


}

.dashboardGridBg {
    border-radius: 8px !important;
    border: 1px solid var(--yellow-primary, #FFC540) !important;
    background: rgba(255, 255, 255, 0.05) !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    /* width: 206px; */


}

.dashboardGrid {
    padding: 16px !important;
}

.dashboardText {
    font-family: "Poppins", sans-serif !important;


}

.chartbox {
    border-radius: 8px;
    border: 1px solid #4a5058;
    background: rgba(255, 255, 255, 0.00);
    padding: 15px
}

.bg-color {
    background: 'rgba(225, 232, 240, 0.50)'
}

.chartBoxText {
    color: var(--White, #FFF);
    /* font-family: Lato; */
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.align-center {
    align-items: center !important;
}

#demo-select-small {
    height: 40px !important;
    padding: 0 !important;
    background: rgba(225, 232, 240, 0.5) !important;
    width: 163.26px;
    border-radius: 8 px !important;
}

.box-border {
    background-image: repeating-linear-gradient(4deg, #ffffff, #ffffff 24px, transparent 15px, transparent 41px, #ffffff 41px), repeating-linear-gradient(104deg, #ffffff, #ffffff 15px, transparent 15px, transparent 41px, #ffffff 41px), repeating-linear-gradient(184deg, #ffffff, #ffffff 14px, transparent 15px, transparent 41px, #ffffff 39px), repeating-linear-gradient(293deg, #ffffff, #ffffff 18px, transparent 18px, transparent 41px, #ffffff 41px);
    background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.metric-head {
    color: var(--White, #FFF);
    font-size: 20px;
}

.MuiMenu-paper{
    max-height: 300px !important;
}

.rdrCalendarWrapper{
    position: absolute;
    right: 100px;
    border: 1px solid #ffc540;
    background-color: #3a3e3f;
    color: black;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.rdrDateInput{background: #3a3e3f !important;}
.rdrDayNumber span{
    color: white;
}