.clr-btn {
    border-radius: 20px !important;
    border: 1px solid #ffc540 !important;
    color: #ffc540 !important;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25) !important;
    font-size: 12px !important;
    text-transform: capitalize !important;
    width: 80px;
    height: 40px
}

.bg-color ul {
    background: var(--Charcoal, #3A3E3F);


}

.no-box {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

.no-box li {

    padding-left: 0;

}

.time-txt {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: normal;
    color: var(--Gray-1, #C7CCD0);
    line-height: 9px;
}

.list-item span {
    color: var(--White, #FFF);
    font-family: "Poppins", sans-serif !important;
    font-size: 146x;
}

.upload-btn {
    color: #000 !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    text-transform: capitalize !important;
    text-align: right !important;
    height: 40px;
    background: linear-gradient(180deg, #EEAB10 0%, #FFC133 100%);

}

.bg-box {
    background-color: rgba(255, 255, 255, 0.1);

}

.heading-notify {
    font-size: 24px;
}

.headerHr {
    margin: 0;
    border: 1px solid #C7CCD0;
    width: 100%;
}

.width-hr {
    width: 100%;
}

.readNotification {
    background: #3a3e3f;
    margin-left: -15px;
    margin-right: -15px;
    width: -webkit-fill-available !important;
    margin-top: -15px;
    padding: 15px;
    padding-bottom: 0px
}

.activeFilterButton {
    width: 123px !important;
    height: 35px !important;
    flex-shrink: 0 !important;
    border-radius: 8px !important;
    border: 1px solid var(--Yellow---Primary, #FFC540) !important;
    background: rgba(255, 197, 64, 0.10) !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    color: var(--Yellow---Primary, #FFC540) !important;
    font-family: Poppins !important;
    font-size: 17px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
}

.disableFilterButton {
    color: #B0B0B4 !important;
    font-family: Poppins !important;
    font-size: 17px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal
}