body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.h-40 {
  height: 40px !important;
}



#snackbarTemp {
  visibility: hidden;
  min-width: 250px;
  /* margin-left: 32%; */
  margin-right: 0px;
  background-color: #ee3f3f;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  /* left: 50%; */
  right: 0%;
  margin-right: 10px;
  top: 10px;
  font-size: 17px;
  z-index: 9999;
}

#snackbarTemp.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }

  to {
    top: 10px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }

  to {
    top: 10px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 10px;
    opacity: 1;
  }

  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 10px;
    opacity: 1;
  }

  to {
    top: 0;
    opacity: 0;
  }
}