#demo-simple-select {
    border-radius: 12px !important;
    border: 1px solid var(--White, #FFF) !important;
    background: rgba(255, 255, 255, 0.10) !important;
    width: 177px !important;
}

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus {
    border-radius: 12px !important;
}

.no-user-found {
    height: 100% !important;
    width: 526px;
}

.user-heading {

    font-size: 24px;
    font-weight: 400;


}
.MuiMenu-paper{
    max-height: 300px !important;
}