@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
body{
  background: var(--charcoal, #3A3E3F);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

input:-webkit-autofill,
.MuiInputBase-root:has(> input:-webkit-autofill) {
  background-color: #3A3E3F !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* variables */
@font-face {
  font-family: Poppins;
  src: url(../public/Poppins-Regular.ttf);
}

:root {
  --main-color: linear-gradient(180deg, #EEAB10 0%, #FFC133 100%);
  --tab-bg-color: #3A3E3F;
  --white-color: #fff;
  --grey-color: #b2b8be;
  --heading-color: #617388;
  --border-color: #c8c9ca;
  --bg-inner-color: #4E5152;
  --text-yellow: #FFC540;

}

.pd-0 {
  padding: 0 !important;
}

.tab-font {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  color: var(--text-yellow) !important;
}

.tab-font button {
  min-width: 75px !important;
}

.tab-section-inner {
  display: flex;
  align-items: center;
}

.tab-btn {
  min-width: 75px !important;
}

/* variable end*/

/*breadcrumb*/

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 12px;
}

.grey-color {
  color: var(--grey-color);
  font-size: 12px;
  font-weight: 400;
}

.heading {
  font-weight: 700;
  line-height: 34px;
  text-align: start;
  color: var(--heading-color);
}

hr {
  border: 1px solid var(--border-color);
}

/*breadcrumb end*/

/* tab section*/

.tab-section {
  border: 1px solid var(--border-color);
  border-radius: 10px;
  overflow: hidden;
}


.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--charcoal, #3A3E3F) !important;
  font-weight: 700 !important;

}


/* .Mui-active {
  left: calc(-50% + 42px) !important;
} */


.outLet {
  margin-left: 65px;
  margin-top: 64px;
  padding: 20px;
  min-height: 100vh;
  height: 100%;
  background: var(--charcoal, #3A3E3F)
}

.css-heg063-MuiTabs-flexContainer {
  gap: 100px;
  padding-left: 20px;
}

.tab-bg {
  background-color: #3A3E3F !important;

}

.stepper-section {
  padding: 25px 0;
  background: #3a3e3f;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -24px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verticalStepLabel {
  color: var(--white-color) !important;
  ;
  font-weight: 700;
}

.verticalStepperIcon_Completed {
  /* height: 40px;
  width: 40px; */
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: -7px; */
  margin-left: -2px;
  background: var(--main-color);
  border-radius: 50%;
  /* color: var(--white-color); */
  color: #3A3E3F;

}


.verticalStepperIcon_Inprogress {
  /* height: 40px;
  width: 40px; */
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: -7px; */
  margin-left: -2px;
  /* background: var(--main-color); */
  border-radius: 50%;
  /* color: var(--white-color); */
  border: 3px solid #ffc540;

}

.verticalStepperIcon_Disabled {
  /* height: 40px;
  width: 40px; */
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: -7px; */
  margin-left: -2px;
  /* background: var(--main-color); */
  border-radius: 50%;
  border: 3px solid #d6d8de;
  /* color: var(--white-color); */
  /* border : 3px solid var(--white-color); */

}

.verticalStepLabelDisabled {
  color: #afafaf;
  font-weight: normal;
}

.dateLabel {
  color: var(--white-color) !important;
  ;

}

.MuiCollapse-wrapperInner,
.MuiCollapse-vertical {
  margin-left: 3px;
  text-align: left;
}

.stepDescription {
  color: var(--white-color) !important;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;

}

.deliveryLocationsContainer {
  border-radius: 8px;
  border: 1px solid var(--gray-1, #C7CCD0);
  background: var(--charcoal, #3A3E3F);
  width: 470px;
  padding: 20px;
  padding-top: 0px;
}

.deliveryLocationsHeadingText {
  color: var(--white-color) !important;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.deliveryLocationName {
  color: var(--white, #FFF);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.deliveryLocationArea,
.deliveryLocationCity,
.deliveryLocationPin {
  color: var(--gray-1, #C7CCD0);
  font-family: Roboto;
  /* font-size: 14px; */
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-right: 10px;
  /* 19.6px */
}

.locationIndex {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.locationIndexText {
  width: 40px;
  height: 40px;
  border: 1px solid var(--white, #FFF);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white, #FFF);
}

.deliveryLocationButton {
  border-radius: 20px !important;
  border: 1px solid var(--yellow-primary, #FFC540) !important;
  background: var(--charcoal, #3A3E3F) !important;
  color: var(--yellow-primary, #FFC540) !important;
}

.deliveryLocationStatus {
  height: 6px;
  display: block;
  background-color: var(--yellow-primary, #FFC540) !important;
  border-radius: 5px !important;

}

.activeVerticalBar+.MuiStepConnector-vertical>.MuiStepConnector-line {
  width: 3px !important;
  background: var(--yellow-primary, #FFC540) !important;
}

.css-14yr603-MuiStepContent-root {
  border-left: 4px solid var(--yellow-primary, #FFC540) !important;
}

/*tab section end*/

.progressBarText {
  position: absolute;
}

.progressBarText-orderPlaced {
  margin-left: 120px;
  opacity: 0.5;
  color: white;
}

.progressBarText-shipping {
  margin-left: 130px;
}

.progressBarText-audit {
  margin-left: 110px;
}

.progressBarText-ingestion {
  margin-left: 130px;
}

.step-shipping>.MuiStepConnector-horizontal {
  top: 20px !important;
  left: calc(-50% + 90px) !important;
  right: calc(50% + 30px) !important;
}

.step-audit>.MuiStepConnector-horizontal {
  top: 20px !important;
  left: calc(-50% + 105px) !important;
  right: calc(50% + 30px) !important;
}

.step-ingestion>.MuiStepConnector-horizontal {
  top: 20px !important;
  left: calc(-50% + 82px) !important;
  right: calc(50% + 30px) !important;
}

.horizontalStepperIcon_Completed {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: -7px; */
  background: #009621;
  border-radius: 50%;
  color: var(--white-color);
}

.horizontalStep-Completed {
  border: "3px solid #FFC540",
}

.horizontalStep-Active {
  border: "3px solid #FFC540",
}

.horizontalStep-Pending {
  border: "3px solid #FFC540",
}

.horizontalStepper-completed>.MuiStepLabel-alternativeLabel>.MuiStepLabel-iconContainer {
  /* border: 3px solid #FFC540; */
  border-radius: 50%;
}


.horizontalStepper-active>.MuiStepLabel-alternativeLabel>.MuiStepLabel-iconContainer {
  border: 3px solid #FFC540;
  border-radius: 50%;

}

.horizontalStepper-pending>.MuiStepLabel-alternativeLabel>.MuiStepLabel-iconContainer {
  border: 3px solid #C7CCD0;
  border-radius: 50%;

}

.MuiTabPanel-root {
  border: 1px solid #4E5152;
  background: #4E5152;
}

.MuiCheckbox-root {
  color: white !important;
}

::-ms-reveal {
  display: none;
}


/* *{
  transition: all 0.6s;
}

html {
  height: 100%;
}

body{
  font-family: 'Lato', sans-serif;
  color: #888;
  margin: 0;
} */

#main {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.fof {
  display: table-cell;
  vertical-align: middle;
}

.fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type .5s alternate infinite;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }

  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}

.MuiSelect-select {
  text-align: left !important;
}

.MuiMenu-list {
  background: #3a3e3f !important;
  color: white !important;
}


#canvas-container {
  height: 60vh;
  width: 60vw;
  position: relative;
}

@media (min-width: 768px) {
  #canvas-container {
    /* height: auto; */
    height: 60vh;
    width: auto;
  }
}

/* ***** Table Header Distinguish */
.MuiTableCell-head {
  background: #4e5152;
}

/* ***** Pagination Section Start */
.MuiPagination-root .MuiPagination-ul {
  flex-wrap: nowrap;
}

.MuiPagination-root .MuiPagination-ul li:first-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* MuiPaginationItem-previousNext */
.MuiPagination-root .MuiPagination-ul li:first-child>button {
  width: 100px !important;
  border-radius: 4px;
}

.MuiPagination-root .MuiPagination-ul li:first-child>button::after {
  margin-left: 10px;
  content: 'Previous';
}

.MuiPagination-root .MuiPagination-ul li:last-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.MuiPagination-root .MuiPagination-ul li:last-child>button::before {
  margin-right: 10px;
  content: 'Next';
}

.MuiPagination-root .MuiPagination-ul li:last-child>button {
  width: 100px;
  border-radius: 4px;
}

.MuiPagination-root .MuiPagination-ul li>button {
  font-weight: 800;
}

/* ***** Pagination Section End */

/* ***** Dialog Css */
.MuiDialogTitle-root {
  overflow: hidden;
}

.MuiDialogContent-root {
  overflow-y: auto;

}

.autoFillInput:-webkit-autofill {
  /* -webkit-box-shadow: 0 0 0 1000px white inset;
  background-color: #3A3E3F !important; */
}

input,
select {
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.hideElement {
  display: none !important;
}

.selectRestore {
  border: 1px solid white;

}

.selectRestore input {
  color: #ffff;
}

.auditTable {
  /* display: inline-block; */
  margin-top: 120px;
  /* border: 2px solid #787a7b; */
  /* border-radius: 20px; */
  /* padding: 19px; */
}